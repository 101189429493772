import {DATABASE} from "../services/database.js";
import {userWordsMap} from "../stores/userWordsMap.js";
import {proVersion} from "$lib/stores/proVersion.js";
import {getInitialSettingsDoc} from "$lib/helper-fns/get-initial-settings-doc.js";

export async function routine_newUser(user) {
    console.log('newUserRoutine')
    const uid = user.uid
    let step
    try {
        step = 'write initial userWordsMainDoc'
        await DATABASE.setDoc(DATABASE.userWordsMainCollection, uid, {})

        step = 'write initial proVersionDoc'
        await proVersion.writeInitialDoc(uid)

        step = 'write initial userSettingsDoc'
        const initialSettingsDoc = getInitialSettingsDoc(user)
        await DATABASE.setDocMerge(DATABASE.userSettingsCollection, uid, initialSettingsDoc)

        step = 'load and save persisted userWords'
        userWordsMap.loadAndSavePersistedUserWords()

    } catch (error) {
        console.error('ERROR routine_newUser - ', step, error)
    }
}