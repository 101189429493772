import Sqids from "sqids";

export function getInitialSettingsDoc(user) {
    try {
        const createdAt = user.metadata.createdAt
        const sqids = new Sqids({
            alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        })
        const initialDoc = {
            referral: {
                code: sqids.encode([createdAt]),
                by: localStorage.getItem('ref') ?? null,
            }
        }
        localStorage.removeItem('ref')
        return initialDoc
    } catch (error) {
        console.error('ERROR creating initial settings doc', error)
        return {}
    }
}