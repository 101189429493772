import * as Sentry from '@sentry/sveltekit';
import {timer} from "../utils/timer.js";
import {userWordsMap} from "../stores/userWordsMap.js";
import {userSettings} from "../stores/userSettings.js";
import {reviewCount} from "$lib/stores/reviewCount.js";
import {proVersion} from "$lib/stores/proVersion.js";
import {subscription} from "$lib/services/subscription.js";
import {verbConjugations} from "$lib/data/verb-conjugations.js";

export async function routine_login(user) {
    const timer_routine_login = timer('user')
    let step
    try {
        step = 'Sentry setUser'
        Sentry.setUser({id: user.uid})

        step = 'init userWordsMap'
        await userWordsMap.init()

        step = 'load user settings'
        await userSettings.init()

        step = 'init reviewCount'
        await reviewCount.init()

        step = 'init pro'
        proVersion.init()

        step = 'init subscription'
        await subscription.connect(user.uid)

        step = 'init verb conjugations'
        await verbConjugations.init()

        // step = 'showPopupModal'
        // showPopupModal()

    } catch (error) {
        console.error('ERROR routine_login - step:', step, error)
    }

    try {
        window.Tawk_API?.setAttributes({jobTitle: user.uid}, error => {
            if (error === undefined) return
            console.error('ERROR tawk.setAttributes', error)
        });
    } catch (error) {
        // Who cares.
    }
    timer_routine_login()
}