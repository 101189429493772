import {Compressor} from "../services/compressor.js";
import {ASSETS_HOST} from "../../consts.js";
import {parseNC, Sentence$} from "../scala.js";

export async function loadWordsSentences() {
    const response = await fetch(`${ASSETS_HOST}/ws-1.gzip`)
    const blob = await response.blob()
    const arrBuff = await blob.arrayBuffer()
    const [wordStr, sentenceStr] = Compressor.inflate(arrBuff).split('$$$')
    const words = parseNC(wordStr)
    const sentences = Sentence$.parseSeq(sentenceStr)
    return [words, sentences]
}