import {writable} from "svelte/store";
import {Filter} from "../utils/filter";

export const displayWords = (() => {
    let initValue = []
    const {subscribe, set} = writable(initValue)

    let allWords = []
    return {
        subscribe,
        set,
        init(startValue) {
            allWords = startValue
            set(startValue)
        },
        filter(filterSettings, userWordsMap){
            // spreaded Array wichtig, damit sort nicht $allWords ändert!
            let words = [...allWords]

            // All, UserWords, KNOWN or LEARN
            words = Filter.filterUserWords(words, userWordsMap, filterSettings.words)

            words = Filter.filterSearchTerm(words, filterSettings.search)

            words = Filter.filterStaticOptions(words, filterSettings);

            // markFirstEnglish - last step!
            if (filterSettings.search) words = Filter.markFirstEnglishWord(words)

            set(words)
        }
    }
})();

