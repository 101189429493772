import {ASSETS_HOST} from "../../consts.js";

class VerbConjugations {

    value = {}

    constructor() { }

    async init() {
        const response = await fetch(`${ASSETS_HOST}/vc.json`)
        if(response.ok) {
            this.value = await response.json()
        }else {
            // QUESTION that verb conjugations can't be fetched shouldn't black whole app..?!
            console.error('Failed to fetch verb conjugations')
        }
    }
}

export const verbConjugations = new VerbConjugations()