import {writable} from 'svelte/store'
import {AUTH} from "$lib/services/auth.js";
import {DATABASE} from "$lib/services/database";
import {DB_COLLECTION_REVIEW_COUNT} from "../../consts.js";
import {logInProduction} from "$lib/utils/logInProduction.js";
import {navigating} from "$app/stores";

export const reviewCount = (() => {

    const {subscribe, set, update} = writable(0)

    let key = getKeyOfToday()

    let uid = null
    let unsubSaveSubscription = () => {}
    let unsubNavigatingSubscription = () => {}

    function getPath() {
        const year = new Date().getFullYear()
        return DB_COLLECTION_REVIEW_COUNT + '/' + year + '/' + DB_COLLECTION_REVIEW_COUNT
    }

    function getKeyOfToday() {
        const now = new Date()
        const month = String(now.getMonth() + 1).padStart(2, '0')
        const day = String(now.getDate()).padStart(2, '0')
        return `${month}-${day}`
    }

    function checkKey() {
        const keyOfToday = getKeyOfToday()
        if (key !== keyOfToday) {
            //NEW DAY
            key = keyOfToday
            set(0)
            logInProduction('NEW DAY - restart reviewCount')
        }
    }

    let interval

    function startCheckKeyInterval() {
        //started
        // when init() called on '/learn'
        // when navigating to route '/learn'
        // when document.visibilityState == 'visible' && pathname === '/learn'
        checkKey()
        if (!interval) interval = setInterval(checkKey, 5000)
    }

    function stopCheckKeyInterval() {
        clearInterval(interval)
        interval = null
    }

    function handleNavigatingChange(navigating) {
        if (!navigating) return
        const from = navigating.from.route.id
        const to = navigating.to.route.id
        if (to === '/learn') {
            startCheckKeyInterval()
        } else if (from === '/learn') {
            stopCheckKeyInterval()
        }
    }

    function handleVisibilityChange() {
        if (location.pathname !== '/learn') return
        if (document.visibilityState === 'visible') {
            startCheckKeyInterval()
        } else if (document.visibilityState === 'hidden') {
            stopCheckKeyInterval()
        }
    }

    async function save(reviewCount) {
        const doc = {
            [key]: reviewCount
        }
        try {
            await DATABASE.setDocMerge(getPath(), uid, doc)
        } catch (error) {
            console.error('ERROR writing reviewCount to DB', error)
        }
    }

    return {
        subscribe,
        async init() {
            uid = AUTH.getUid()
            key = getKeyOfToday()
            const doc = await DATABASE.getDoc(getPath(), uid)
            const savedCount = doc && doc[key]
            const startCount = savedCount ?? 0
            set(startCount)
            logInProduction('rc init >> ' + startCount)
            unsubSaveSubscription = subscribe(save)
            //checkKeyInterval
            unsubNavigatingSubscription = navigating.subscribe(handleNavigatingChange)
            document.addEventListener('visibilitychange', handleVisibilityChange)
            if (location.pathname === '/learn') startCheckKeyInterval()
        },
        addOne() {
            checkKey()
            update((currentCount) => {
                const plusOne = currentCount + 1
                logInProduction('rc >> ' + currentCount + '+1 >> ' + plusOne)
                return plusOne
            })
        },
        terminate() {
            unsubSaveSubscription()
            //checkKeyInterval
            unsubNavigatingSubscription()
            document.removeEventListener('visibilitychange', handleVisibilityChange)
            stopCheckKeyInterval()
        }
    }
})()