// noinspection UnnecessaryLabelJS

import {timer} from "$lib/utils/timer.js";
import {loadWordsSentences} from "$lib/utils/loadWordsSentences.js";
import {setAllWords} from "$lib/data/allWords.js";
import {initWordIdToWord} from "$lib/data/wordIdToWord.js";
import {initFilterOptions} from "$lib/data/filterOptions.js";
import {displayWords} from "$lib/stores/displayWords.js";
import {setAllSentences} from "$lib/data/allSentences.js";
import {initWordIdToSentences} from "$lib/data/wordIdToSentences.js";
import {initSentenceIdToSentence} from "$lib/data/sentenceIdToSentence.js";
import {AUTH} from "$lib/services/auth.js";
import {logInProduction} from "$lib/utils/logInProduction.js";
import {version} from '$app/environment';

async function init() {

    let step
    try {
        const timer_init = timer('init')
        const timer_data = timer('data')

        step = 'initialize words&sentences'
        const [words, sentences] = await loadWordsSentences()

        setAllWords(words)
        initWordIdToWord(words)
        initFilterOptions(words)
        displayWords.init(words)

        setAllSentences(sentences)
        initWordIdToSentences(sentences)
        initSentenceIdToSentence(sentences)

        timer_data()

        step = 'initialize Auth'
        await AUTH.init()

        timer_init()

        logInProduction('version ' + version)

    } catch (error) {
        console.error(`ERROR in ${step}`, error)
    }
}

export const runInitRoutine = init()