import {resolveOption} from "$lib/scala.js";

export function sortByIndex(sentences, id) {
    return sentences.sort((s1, s2) => {
            const wL1 = s1.wordLinksWithId(id)[0]
            const i1 = resolveOption(wL1.i)
            const wL2 = s2.wordLinksWithId(id)[0]
            const i2 = resolveOption(wL2.i)

            //None() === ''
            if (i1 === '' && i2 !== '') {
                return 1
            } else if (i1 !== '' && i2 === '') {
                return -1
            } else {
                return i1 - i2
            }
        }
    )
}