// noinspection ES6UnusedImports

import {firebaseApp} from "./firebase.js";
import {getFunctions, httpsCallable, connectFunctionsEmulator} from 'firebase/functions';
import {browser} from "$app/environment";

const functions = getFunctions(firebaseApp, 'europe-west2');

// if (browser && location.hostname === 'localhost') {
//     connectFunctionsEmulator(functions, "localhost", 5005);
// }

export const FUNCTIONS = {

    async emailAuthLink(data) {
        const callFn = httpsCallable(functions, 'emailAuthLink')
        await callFn(data)
    },

    async sendEmail(data) {
        const callFn = httpsCallable(functions, 'sendFeedbackEmail')
        await callFn(data)
    },

    async resetWords() {
        const callFn = httpsCallable(functions, 'resetWords')
        await callFn()
    },

    async cancelSubscription(subscriptionId) {
        const callFn = httpsCallable(functions, 'paddleCancelSubscription')
        await callFn({subscriptionId})
    },

    async reactivateSubscription(subscriptionId) {
        const callFn = httpsCallable(functions, 'paddleReactivateSubscription')
        await callFn({subscriptionId})
    },

    async getPaymentMethodUpdateTransaction(subscriptionId) {
        const callFn = httpsCallable(functions, 'paddleGetPaymentMethodUpdateTransaction')
        return await callFn({subscriptionId})
    }
}
