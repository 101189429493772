import {writable} from "svelte/store";

export const loggedIn = (() => {
    const {subscribe, set} = writable(null)
    return {
        subscribe,
        set(status) {
            // console.log('set loggedIn', status)
            // console.trace()
            set(status)
        }
    }
})();