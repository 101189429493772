import pako from 'pako';

export const Compressor = {

    inflate(uint8Arr) {
        return pako.inflate(uint8Arr, {to: 'string'})
    },

    deflate(string) {
        return pako.deflate(string)
    }
}
