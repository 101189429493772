import {None, resolveOption, Some, UserWord$} from "$lib/scala.js";
import {wordIdToWord} from "$lib/data/wordIdToWord.js";
import {wordIdToSentences} from "$lib/data/wordIdToSentences.js";
import {ASSETS_HOST} from "../../../consts.js";

export async function validateUserWordChanges(changes) {

    const validated = []
    const toSave = []
    const toDelete = []

    for (let change of changes) {

        const userWord = UserWord$.fromNative(change.doc.data())
        let userWordValidatedId = userWord

        const foundWord = wordIdToWord[userWord.id]

        if (!foundWord) {
            console.warn('NO Word found for wordId >>', userWord.id)
            toDelete.push(userWord)
            const response = await fetch(`${ASSETS_HOST}/wids.json`)
            const wordIdChanges = await response.json()
            const newWordId = wordIdChanges[userWord.id]
            if (newWordId) {
                console.warn('NEW wordId >>', newWordId)
                // copy via native JSON so that id can be changed
                let userWordJsonCopy = UserWord$.toNative(userWord)
                userWordJsonCopy.id = newWordId
                userWordValidatedId = UserWord$.fromNative(userWordJsonCopy)
            } else {
                console.warn('NO new wordId found')
                continue
            }
        }

        // from here userWordValidatedId - SCALA UserWord !

        const sentenceId = resolveOption(userWordValidatedId.sentenceId)
        const sentences = wordIdToSentences[userWordValidatedId.id] ?? []
        const sentenceWithSavedIdFound = sentences.some(sentence => sentence.id === sentenceId)
        const wordIdHasSentences = sentences.length > 0
        const newSentenceId = wordIdHasSentences ? Some(sentences[0].id) : None()

        if ((sentenceId && sentenceWithSavedIdFound) || (!sentenceId && !wordIdHasSentences)) {
            if (foundWord) {
                validated.push(userWordValidatedId)
            } else {
                toSave.push(userWordValidatedId)
            }

        } else if ((sentenceId && !sentenceWithSavedIdFound)) {
            console.warn('NO Sentence with id >> ', sentenceId, " found containing ", userWordValidatedId.id,
                wordIdHasSentences ? ` >> new sentence: ${sentences[0].sentence}` : ` >> NO other sentence found >> sentenceId: ${newSentenceId.toString()}`)
            userWordValidatedId.sentenceId = newSentenceId
            toSave.push(userWordValidatedId)

        } else if (!sentenceId && wordIdHasSentences) {
            console.warn('Sentence ADDED for >> ', userWordValidatedId.id, ' >> ', sentences[0].sentence)
            userWordValidatedId.sentenceId = newSentenceId
            toSave.push(userWordValidatedId)
        }
    }

    return {validated, toSave, toDelete}
}

