import {sortByIndex} from "../utils/sortByIndex.js";
import {resolveOption} from "$lib/scala.js";

export let wordIdToSentences = {}

export function initWordIdToSentences(sentences) {

    wordIdToSentences = sentences.reduce((map, sentence) => {
        sentence.wordLinks.forEach(wordLink => {

            const hasTranlation = resolveOption(wordLink.t) !== '-'
            const justOneOccurence = countOccurence(wordLink, sentence) === 1
            const notDisabled = resolveOption(wordLink.i) !== -1

            if (hasTranlation && justOneOccurence && notDisabled) {

                let id = resolveOption(wordLink.id)
                let entry = map[id]

                if (entry) {
                    map[id] = [...map[id], sentence]
                } else {
                    map[id] = [sentence]
                }
            }
        })
        return map
    }, {})

    // SORT

    const wordIds = Object.keys(wordIdToSentences)

    wordIds.forEach(id => {
        const sentences = wordIdToSentences[id]
        wordIdToSentences[id] = sortByIndex(sentences, id)
    })

}


function countOccurence(wordLink, sentence) {
    const wordLinkId = resolveOption(wordLink.id)
    return sentence.wordLinks.reduce((sum, wL) => {
        let partId = resolveOption(wL.id)
        if (partId === wordLinkId) {
            sum += 1
        }
        return sum
    }, 0)
}
