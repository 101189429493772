import {writable} from 'svelte/store'
import {DATABASE} from "$lib/services/database.js";
import {FUNCTIONS} from "$lib/services/functions.js";
import {goto} from "$app/navigation";

export class Subscription {
    #database = null
    #functions = null

    #store = writable(null)
    subscribe = this.#store.subscribe
    #unsubscribeDatabaseQuery = () => {}

    constructor(database, functions) {
        this.#database = database
        this.#functions = functions
    }

    async connect(uid) {
        // resolve with the first snapshot of the query
        return new Promise(async (resolve, reject) => {
            this.#unsubscribeDatabaseQuery = this.#database.subscribeDoc('subscription', uid, doc => {
                    if (doc.exists()) {
                        const subscription = doc.data()
                        if (subscription.items.length !== 1) {
                            console.error(`ERROR incorrect subscription item count ${subscription.items.length} subscriptionId ${subscription.id}`)
                        }
                        this.#store.set(subscription)
                    }else {
                        // NOTE necessary for when subscription is canceled..!
                        this.#store.set(null)
                    }
                    resolve()
                },
                error => {
                    console.error(`ERROR subscription.connect() - ${error.message}`)
                    reject(error)
                })
        })
    }

    disconnect() {
        this.#unsubscribeDatabaseQuery()
        this.#unsubscribeDatabaseQuery = () => {}
        this.#store.set(null)
    }

    async cancel(subscriptionId) {
        try {
            await this.#functions.cancelSubscription(subscriptionId)
        } catch (error) {
            console.error('ERROR cancel subscription', error)
            throw error
        }
    }

    async reactivate(subscriptionId) {
        try {
            await this.#functions.reactivateSubscription(subscriptionId)
        } catch (error) {
            console.error('ERROR reactivate subscription', error)
            throw error
        }
    }

    async updatePaymentMethod(subscriptionId) {
        try {
            const payload = await this.#functions.getPaymentMethodUpdateTransaction(subscriptionId)
            const transaction = payload.data
            const transactionId = transaction.id
            await goto(`/subscription?_ptxn=${transactionId}`)
        } catch (error) {
            console.error('ERROR updating payment Method', error)
            throw error
        }
    }

    getPlanName(subscription) {
        // NOTE subscription as parameter to make function call reactive
        if (subscription === null) {
            console.error('ERROR subscription.getPlan() - subscription === null')
            return
        }
        const monthsTotal = subscription.previouslyPrepaidMonths + subscription.monthsSubscribed
        return monthsTotal > 12 ? 'Platinum'
            : monthsTotal > 6 ? 'Gold'
                : monthsTotal > 3 ? 'Silver'
                    : 'Bronze'
    }
}

export let subscription = new Subscription(DATABASE, FUNCTIONS)