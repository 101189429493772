import {loggedIn} from "../stores/loggedIn";
import {firebaseApp} from './firebase'
import {FUNCTIONS} from "./functions.js";
import {routine_newUser} from "../routines/routine_newUser.js";
import {routine_login} from "../routines/routine_login.js";
import {routine_logout} from "$lib/routines/routine_logout.js";
import {redirect} from "@sveltejs/kit";
import {browser} from "$app/environment";
import {replaceState} from "$app/navigation";
// noinspection ES6UnusedImports
import {connectAuthEmulator, verifyBeforeUpdateEmail} from 'firebase/auth'

// login with Google imports
import {
    deleteUser,
    getAdditionalUserInfo,
    getAuth,
    getRedirectResult,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    // sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithRedirect,
    signOut,
    // updateEmail,
    // connectAuthEmulator
} from 'firebase/auth'

const auth = getAuth(firebaseApp)

// for emulating the authentication locally start emulation server with firebase emulators:start --only auth
// warning 'WARNING: You are using the Auth Emulator, which is intended for local testing only. Do not use with production credentials.'
// >> 'it means don't type in your real password or anything else sensitive since it's going to be exposed over http when traveling to the emulator.'

// WENN AUTH EMULIERT MUSS AUCH FIRESTORE EMULIERT WERDEN - wegen Rules !!! - Rules? Auth link funktioniert auf jeden Fall nicht...?! Problem in function code?
// if (location.hostname === 'localhost') {
//     connectAuthEmulator(auth, "http://localhost:9099")
// }

// To apply the default browser preference instead of explicitly setting it.
// stand bei Google login - beeinflusst auch Authentication Mail Sprache?
auth.useDeviceLanguage()


export const AUTH = {

    user: null,

    async init() {

        if (!browser) return

        const signInWithEmail = isSignInWithEmailLink(auth, window.location.href)
        if (signInWithEmail) {
            if (auth.currentUser) {
                // if already logged in just clean url
                replaceState(window.location.pathname, {})
            } else {
                // try signing in user
                try {
                    // console.log('signInWithEmailLink >>>')
                    let email = window.localStorage.getItem('emailForSignIn');
                    if (!email) {
                        // User opened the link on a different device. To prevent session fixation
                        // attacks, ask the user to provide the associated email again. For example:
                        email = window.prompt('Please provide your email for confirmation');
                    }
                    let credential = await signInWithEmailLink(auth, email, window.location.href)
                    const details = getAdditionalUserInfo(credential)
                    if (details.isNewUser) {
                        console.log('+++ NEW USER +++')
                        await routine_newUser(credential.user)
                    }
                    window.localStorage.removeItem('emailForSignIn');
                    replaceState(window.location.pathname, {})
                } catch (error) {
                    window.localStorage.removeItem('emailForSignIn');
                    console.error('ERROR while signing in with email', error)
                    if (error.code === 'auth/invalid-action-code') {
                        alert('The login link you used is no longer valid,please request a new one')
                        redirect(307, `/signin`);
                    } else {
                        alert(`There was an error while signing in.\nError code: ${error.code}`)
                    }
                }
            }
        }

        const redirectResult = await getRedirectResult(auth)
        if (redirectResult) {
            try {
                // This gives you a Google Access Token. You can use it to access Google APIs.
                // const OAuthCredential = GoogleAuthProvider.credentialFromResult(redirectResult);
                // const token = OAuthCredential.accessToken;

                let credential = redirectResult
                const details = getAdditionalUserInfo(credential)
                if (details.isNewUser) {
                    console.log('+++ NEW USER +++')
                    await routine_newUser(credential.user)
                }
            } catch (error) {
                const email = error.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.error('error while signing in with google - getting redirect result', email, credential, error)
            }
        }

        return new Promise((resolve) => {
            onAuthStateChanged(auth, async user => {
                console.log('onAuthStateChanged >>>')
                if (user) {
                    console.log('USER', user.uid)
                    await routine_login(user)
                    loggedIn.set(true);
                } else {
                    loggedIn.set(false);
                    routine_logout()
                    console.log('logged OUT')
                }
                resolve()
            })
        })
    },

    async logout() {
        await signOut(auth)
    },

    // async signup(email, password) {
    //     let credential = await createUserWithEmailAndPassword(auth, email, password)
    //     console.log('signed up', credential);
    //     await USER.createUser()
    // },
    //
    // async login(email, password) {
    //     let credential = await signInWithEmailAndPassword(auth, email, password)
    //     console.log('logged in', credential);
    // },

    async signInWithEmail(email, url) {
        await FUNCTIONS.emailAuthLink({email, url})
        window.localStorage.setItem('emailForSignIn', email);
    },

    async signInWithGoogle(url) {
        const provider = new GoogleAuthProvider();
        // prevent skipping the account selection step on subsequent
        // logins when only one google account is registered
        provider.setCustomParameters({
            prompt: 'select_account'
        });

        try {
            // google will redirect to current url when calling signIn function
            // so if there's a saved url in history state, set it before calling signin
            replaceState(url, {})
            await signInWithRedirect(auth, provider)
        } catch (error) {
            console.error('Error while signing in with redirect', error)
        }
    },

    async changeEmail(user, newEmail) {
        const actionCodeSettings = {
            url: window.location.origin + '/signin?email=' + encodeURIComponent(newEmail),
        }
        await verifyBeforeUpdateEmail(user, newEmail, actionCodeSettings)
        // TODO send email that notifies of requested change with uid and current email --> check if changed and update in mail service
    },

    async deleteAccount() {
        await deleteUser(auth.currentUser)
    },

    getCurrentUser() {
        return auth.currentUser
    },

    getUserEmail() {
        return auth.currentUser?.email
    },

    getUid() {
        return auth.currentUser?.uid
    }
}
