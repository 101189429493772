import {removeCharacters} from "./removeCharacters.js";

export function sortGermanAlpha(arr) {
    return arr.sort((a, b) => {

        let sortA = checkForDisplayWord(a) ? removeCharacters(a.base.displayWord).toLowerCase() : a.word.toLowerCase()
        let sortB = checkForDisplayWord(b) ? removeCharacters(b.base.displayWord).toLowerCase() : b.word.toLowerCase()

        function checkForDisplayWord(word) {
            return word.base.displayWord
        }

        return sortA.localeCompare(sortB, 'de')
    })
}

export function sortEnglishSearchResultsAlphabetically(words) {
    words.sort((a, b) => {
        const translA =a.searchedTranslations.map(t=> removeToFromVerbs(t)).join(', ')
        const translB =b.searchedTranslations.map(t=> removeToFromVerbs(t)).join(', ')
        return translA.localeCompare(translB)
    })
}


export function shortestSearchedTranslationsFirst(englishWords) {
    const shortest = shortestSearchedTranslationsLength(englishWords)
    englishWords.sort((w1, w2) => {
        const w1Length = getSearchedTranslationsLength(w1)
        const w2Length = getSearchedTranslationsLength(w2)
        if (w1Length === shortest && w1Length !== w2Length) {
            return -1
        } else if (w2Length === shortest && w1Length !== w2Length) {
            return 1
        } else {
            return 0
        }
    })
}

function getSearchedTranslationsLength(word) {
    return word.searchedTranslations.map(t => removeToFromVerbs(t)).join(', ').length
}

function removeToFromVerbs(translation) {
    if (translation.startsWith('to ')) return translation.slice(3, translation.length)
    else return translation
}

function shortestSearchedTranslationsLength(words) {
    const searchedTranslationsLength = words.map(word => getSearchedTranslationsLength(word))
    return Math.min(...searchedTranslationsLength)
}
