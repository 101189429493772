import {shortestSearchedTranslationsFirst, sortEnglishSearchResultsAlphabetically, sortGermanAlpha} from "./sort";
import {removeCharacters} from "./removeCharacters.js";
import {listToArr, parseNC, stringifyNC} from "$lib/scala.js";

export const Filter = {

    filterUserWords(words, userWordsMap, status) {
        if (!status) return words

        return words.filter(word => {
            let userWord = userWordsMap[word.id]
            if (status === 'userWords') {
                return userWord
            } else if (status === 'rest') {
                return !userWord
            } else {
                return userWord?.status.toString() === status
            }
        })
    },

    filterSearchTerm(words, searchTerm) {
        if (!searchTerm) return words

        searchTerm = searchTerm.toLowerCase()

        let german = sortGermanAlpha(words.filter(w => {
            let word = w.word.toLowerCase()
            let displayWord = removeCharacters(w.base.displayWord).toLowerCase()
            return word.startsWith(searchTerm)
                || displayWord.startsWith(searchTerm)
                || displayWord.includes(` ${searchTerm}`)
        }))

        let english = words.filter(w => {
            let translations = listToArr(w.base.translations).map(t => removeCharacters(t).toLowerCase())
            return translations.some(t => t.startsWith(searchTerm))
                || translations.some(t => t.includes(` ${searchTerm}`))
        })
        let deepCopy = parseNC(stringifyNC(english))
            .map(w => {
                w['searchedTranslations'] = listToArr(w.base.translations).filter(t => {
                    t = removeCharacters(t).toLowerCase()
                    return t.startsWith(searchTerm) || t.includes(` ${searchTerm}`)
                })
                w['english'] = true
                return w
            })

        sortEnglishSearchResultsAlphabetically(deepCopy)
        shortestSearchedTranslationsFirst(deepCopy)

        return german.concat(deepCopy)
    },

    filterStaticOptions(words, filterObj) {

        if (filterObj.art) words = words.filter(w => w.art === filterObj.art)
        if (filterObj.level) words = words.filter(w => {
            if (filterObj.level === '-') {
                return w.base.level.toString() === 'NOLEVEL'
            } else {
                return w.base.level.toString() === filterObj.level
            }
        })
        if (filterObj.frequency || filterObj.frequency === 0) words = words.filter(w => w.base.frequency === filterObj.frequency)
        if (filterObj.syllables) words = words.filter(w => listToArr(w.base.syllables).length === filterObj.syllables)

        if (filterObj.art === 'Num') words.sort((w1, w2) => parseInt(w1.num) - parseInt(w2.num))
        if (filterObj.sortAlph && !filterObj.search) words = sortGermanAlpha(words)

        return words
    },

    markFirstEnglishWord(words) {
        let firstEnglish = words.find(w => w.english)
        if (firstEnglish) {
            let index = words.indexOf(firstEnglish)
            words[index].first = true
        }
        return words
    }
}
