import {initializeApp} from 'firebase/app'
import {browser} from "$app/environment";

const config = {
    apiKey: import.meta.env.VITE_FB_API_KEY,
    // NOTE set authDomain to firebase address in development to make it work under localhost
    //  authDomain: import.meta.env.DEV ? "yourwortschatz.firebaseapp.com" : "vocabeo.com",
    //  fix from https://github.com/firebase/firebase-js-sdk/issues/7342
    // authDomain: import.meta.env.VITE_FB_AUTH_DOMAIN,
    authDomain: browser ? document.location.host : '',
    projectId: import.meta.env.VITE_FB_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FB_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FB_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FB_APP_ID,
};

export const firebaseApp = initializeApp(config)
