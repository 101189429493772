import {writable} from 'svelte/store'
import {DATABASE} from "$lib/services/database.js";
import {DB_COLLECTION_PRO_VERSION} from "../../consts.js";
import {AUTH} from "$lib/services/auth.js";
import {add, differenceInCalendarDays, format} from 'date-fns'

export const proVersion = (() => {
    const initialValue = null
    const {subscribe, set, update} = writable(initialValue)

    function calculateActive(until) {
        return differenceInCalendarDays(new Date(), new Date(until)) <= 0
    }

    function updateActive() {
        update((proVersion) => {
            if (proVersion === null || !proVersion.until) {
                return proVersion
            } else {
                return {
                    ...proVersion,
                    active: calculateActive(proVersion.until)
                }
            }
        })
    }

    function handleDocUpdate(doc) {
        if (doc.exists()) {
            const until = doc.data().until
            const orderId = doc.data().orderId
            const active = calculateActive(until)
            set({
                active,
                until,
                orderId,
            })
        }
    }

    return {
        subscribe,
        cancelCollectionSubscription: null,
        interval: null,
        async writeInitialDoc(uid) {
            const now = new Date()
            const until = format(add(now, {weeks: 1}), 'yyyy-MM-dd')
            const proVersionDoc = {
                orderId: null,
                until
            }
            await DATABASE.setDoc(DB_COLLECTION_PRO_VERSION, uid, proVersionDoc)
        },
        init() {
            this.cancelCollectionSubscription = DATABASE.subscribeDoc(DB_COLLECTION_PRO_VERSION, AUTH.getUid(), handleDocUpdate, error => console.error('ERROR subscribing proVersion', error))
            this.interval = setInterval(updateActive, 60000)
        },
        close() {
            if (this.cancelCollectionSubscription) this.cancelCollectionSubscription()
            clearInterval(this.interval)
            set(null)
        },
    }

})();